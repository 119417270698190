/* Colours, fonts and variables */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');
:root {
    --bgBlack: #2A2A2A;
    --bgGrey: #F4F4F4;
    --bgDarkGrey: #e1e1e1;
    --bgGreyFaded: #f7f7f7;
    --bgWhite: #fff;
    --transWhite: rgba(255,255,255,0.15);
    --bgGrnFaded: #F1F7E1;
    --bgOrangeFaded: #FFF0E1;
    
    --txtWhiteBrill: #fff;
    --txtWhite: #D8D8D8;
    --txtWhiteFaded: #A8A8A8;
    --txtBlack: #2A2A2A;
    --txtGrn: #BADA55;
    --txtGrey: #787878;

    --border: #E0E0E0;

    --grn: #BADA55;
    --lghtGrn: #CEE092;
    --orange: #FC9227;
    --red: #FC4C4A;
    --prpl: #d8ccf9;

    /* Ami colours for white labelling */
    --hotPink: #e32088;
    --hotPinkFaded: #ab1465;
    --pinkBg: #fce0f0;
    --blueBg: #e2eff3;
}

/* Key core styles */
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}
.logo {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 2px;
  margin-bottom: 32px;
  padding: 0;
}
.logo img {
    width: 175px;
    opacity: 0.9;
}
.meetami .logo img {
    width: 70px;
}

h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 32px;
}
h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 22px;
}

button.btn, a.btn {
  font-weight: 600;
  background: var(--bgGrey);
  border-radius: 12px;
  color: var(--txtBlack);
  border: none;
  padding: 14px 18px;
  display: inline-block;
  font-size: 15px;
  box-sizing: border-box;
  text-align: center;
  transition: 0.3s;
  min-width: 170px;
  cursor: pointer;
}
a.btn.primary, button.primary, a.btn.upgrade {
  background: var(--grn);
}
a.btn.primary:hover,
button.primary:hover,
a.btn.upgrade:hover {
    background-color: var(--lghtGrn);
}
.meetami a.btn.primary, .meetami button.primary, .meetami a.btn.upgrade { background: var(--hotPink); color: var(--txtWhiteBrill); } 
.meetami a.btn.primary:hover,
.meetami button.primary:hover,
.meetami a.btn.upgrade:hover {
    background-color: var(--hotPinkFaded);
}


a { 
    color: var(--txtBlack); 
    text-decoration: none; 
    padding-bottom: 0; 
    border-bottom: 1px solid var(--txtBlack); 
    transition: 0.3s; 
}
a:hover { border-bottom-color: transparent; }

.back-btn {
    position: relative;
    margin-top: 5px;
    font-size: 14px;
    color: var(--orange);
    border-bottom-color: var(--orange);
}
.back-btn:before {
    content: "<";
    position: absolute;
    left: -15px;
    top: 2px;
}
.meetami .back-btn { color: var(--hotPink); }

p { margin-bottom: 18px; }
p.fig {
  color: var(--txtGrey);
  font-size: 14px;
  margin-bottom: 28px;
}
p.sml {
  font-size: 13px;
}

hr.password-divider {
  border: 0;
  height: 1px;
  background: var(--border);
  margin: 32px 0;
}

.mbtm {
  margin-bottom: 32px;
}

/* Sidebar menu */
header.sidebar {
    height: 100vh;
    width: 262px;
    padding: 42px 22px;
    box-sizing: border-box;
    background: var(--bgBlack);
    color: var(--txtWhite);
    position: fixed;
    top: 0;
    left: 0;
}
header.sidebar .menu, header.sidebar .usr-menu {
    width: 218px;
    box-sizing: border-box;
}
header.sidebar ul {
    list-style: none;
    width: 100%;
    box-sizing: border-box;
}
header.sidebar ul li {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 8px;
}
header.sidebar ul li a {
    display: block;
    color: var(--txtWhite);
    text-decoration: none;
    padding: 15px 17px 15px 54px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    background: url('../img/chatback/dashboard.png') left 17px center / 20px auto no-repeat;
    transition: 0.25s;
}
header.sidebar ul li a.chats { background-image: url('../img/chatback/chats.png'); }
header.sidebar ul li a.settings { background-image: url('../img/chatback/settings.png'); }
header.sidebar ul li a.config { background-image: url('../img/chatback/config.png'); }
header.sidebar ul li a.gpt-content { background-image: url('../img/chatback/content.png'); }
header.sidebar ul li a.logout { background-image: url('../img/chatback/logout.png'); }

header.sidebar ul li a:hover, header.sidebar ul li a.current {
    background-color: var(--transWhite);
}
header.sidebar ul li a.current {
    color: var(--txtGrn);
    background-image: url('../img/chatback/dashboard-grn.png');
}
.meetami header.sidebar ul li a.current {
    background-image: url('../img/ami/dashboard-pink.webp');
    color: var(--hotPink);
}
header.sidebar ul li a.chats.current { background-image: url('../img/chatback/chats-grn.png'); }
header.sidebar ul li a.settings.current { background-image: url('../img/chatback/settings-grn.png'); }
header.sidebar ul li a.config.current { background-image: url('../img/chatback/config-grn.png'); }
header.sidebar ul li a.gpt-content.current { background-image: url('../img/chatback/content-grn.png'); }
header.sidebar ul li a.logout.current { background-image: url('../img/chatback/logout-grn.png'); }

.meetami header.sidebar ul li a.chats.current { background-image: url('../img/ami/chats-pink.webp'); }
.meetami header.sidebar ul li a.settings.current { background-image: url('../img/ami/settings-pink.webp'); }
.meetami header.sidebar ul li a.config.current { background-image: url('../img/ami/config-pink.webp'); }
.meetami header.sidebar ul li a.gpt-content.current { background-image: url('../img/ami/content-pink.webp'); }
.meetami header.sidebar ul li a.logout.current { background-image: url('../img/ami/logout-pink.png'); }

header.sidebar ul li ul { margin-top: 8px; }
header.sidebar ul li ul li a {
    color: var(--txtWhiteFaded);
    font-weight: 400;
    background-image: none !important;
    background-color: none;
    padding: 12px 17px;
    margin-left: 37px;
}
header.sidebar ul li ul li a.current {
    background: none;
}
header.sidebar ul li ul li a:hover {
  background: var(--transWhite);
}

.sidebar .usr-menu {
    position: absolute;
    bottom: 22px;
    left: 22px;
}

/* Tabs */
ul.tabs {
    list-style: none;
    box-sizing: border-box;
    margin-bottom: 22px;
}
ul.tabs li {
    display: inline-block;
    margin-right: 22px;
    padding: 15px 32px;
    background: var(--bgGrey);
    cursor: pointer;
    font-weight: 500;
}
ul.tabs li.active {
    background: var(--grn);
}
.meetami ul.tabs li.active {
    background: var(--hotPink);
    color: var(--txtWhiteBrill);
}

/* Core admin dashboard content */
.main-content {
    width: calc(90% - 250px);
    margin: 42px 5% 22px calc(5% + 250px);
    box-sizing: border-box;
    max-width: 800px;
}
.main-content.wide {
    max-width: 100%;
}

/* Dashboard */
.dashboard .stats {
  margin-bottom: 44px;
  overflow: auto;
}
.dashboard .stats.thirds > div {
    width: 31%;
    margin-left: 3.5%;
    float: left;
    text-align: center;
    background: var(--bgGrey);
    border-radius: 24px;
    padding: 32px;
    box-sizing: border-box;
}
.dashboard .stats.thirds>div:first-child { margin-left: 0; }
.dashboard .stats.thirds>div.green { background: var(--bgGrnFaded); }
.meetami .dashboard .stats.thirds>div.green { background: var(--pinkBg); }
.dashboard .stats.thirds>div.orange { background: var(--bgOrangeFaded); }
.meetami .dashboard .stats.thirds>div.orange { background: var(--blueBg); }

.dashboard .stats.thirds>div p {
    font-size: 14px;
}
.dashboard .stats.thirds > div p span {
    font-size: 54px;
    font-weight: 700;
    margin-bottom: 12px;
}

.progress {
  margin-bottom: 44px;
}
.progress .bar {
  width: 78%;
  background: var(--bgGrey);
  height: 45px;
  border-radius: 12px;
  float: left;
  margin-bottom: 6px;
}
.progress .bar .perc-used {
  height: 100%;
  background: var(--bgBlack);
  border-radius: 12px;
  padding: 12px;
  box-sizing: border-box;
  width: 0;
  transition: 0.5s;
}
.progress .bar .perc-used.orange { background: var(--orange); }
.progress .bar .perc-used.red { background: var(--red); }

.progress a.btn.upgrade { margin-left: 2%; width: 20%; min-width: 0; }

.progress p {
    font-size: 14px;
    clear: left;
}

.installer {
  background: var(--bgGrey);
  border-radius: 24px;
  padding: 32px;
}
.installer .code {
  margin-bottom: 22px;
  background: var(--bgWhite);
  border-radius: 12px;
  padding: 32px;
  font-family: "Courier New", cursive;
  color: var(--txtGrey);
  font-size: 14px;
  line-height: 18px;
}
.installer .code p.block-cover {
    background: var(--prpl);
    color: var(--txtBlack);
    padding: 18px 25px;
    border-radius: 12px;
    text-align: center;
    width: 75%;
    display: block;
    margin: 72px auto;
}
.installer .code p.block-cover span {
    font-weight: 600;
    font-size: 1.2em;
}
.installer .btn {
    margin-right: 22px;
    padding-left: 48px;
}
.installer .btn.wordpress {
    background-image: url('../img/chatback/wordpress.png');
    background-position: left 18px center;
    background-size: 22px;
    background-repeat: no-repeat;
}
.installer .btn.wix {
    background-image: url('../img/chatback/wix.png');
    background-position: left 16px center;
    background-size: 24px;
    background-repeat: no-repeat;
}

/* Packages */
.packages {
    margin: 72px 0 52px 0;
    overflow: visible;
    height: 430px;
}
.packages .package {
    width: 30%;
    float: left;
    background: var(--bgGrey);
    border-radius: 10px;
    padding: 32px 32px 72px 32px;
    box-sizing: border-box;
    text-align: center;
    min-height: 390px;
    position: relative;
    margin: 0 1.5%;
    box-shadow: 0 2px 5px 0 rgba(173, 173, 173, 0.25);
}
.packages .package.major {
    background: var(--bgGrnFaded);
    min-height: 430px;
    margin-top: -20px;
    width: 31%;
    box-shadow: 0 2px 6px 1px rgba(173, 173, 173, 0.25);
}
.meetami .packages .package.major {
    background: var(--pinkBg);
}
.packages .package.current {
    border: 1px solid var(--grn);
}
.meetami .packages .package.current {
    border: 1px solid var(--hotPink);
}
.packages .package h3 {
    margin-bottom: 8px;
}
.packages .package h4 {
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 16px;
  opacity: 0.5;
}
.packages .package ul {
  list-style: none;
  text-align: left;
  margin: 0 0 18px 0;
}
.packages .package ul li {
    padding: 5px 0 5px 26px;
    font-size: 14px;
    background: url('../img/tick.svg') no-repeat left center / 12px auto;
}
.packages .package button.btn.primary {
  position: absolute;
  bottom: 32px;
  left: 50%;
  margin-left: -110px;
  width: 220px;
}
.packages .package button.btn.primary.disabled {
    background: var(--bgDarkGrey);
    cursor: default;
}

.currency.select {
    float: right;
}
.currency.select label {
    font-size: 14px;
    float: left;
    margin: 8px 18px;
}
.currency.select select {
    width: 120px;
    padding: 8px 12px;
    font-size: 14px;
}

.usage {
    clear: both;
    background: var(--bgGreyFaded);
    border-radius: 10px;
    padding: 32px;
}
.usage ul {
    list-style: none;
    margin: 0;
    overflow: auto;
}
.usage ul li {
    width: 33%;
    float: left;
    text-align: center;
    font-size: 14px;
}
.usage ul li span {
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 12px;
    display: block;
}

.cancel {
  font-size: 14px;
  margin-top: 32px;
}

/* Tables */
.main-content table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 22px;
}
.main-content table th {
    border-bottom: 2px solid var(--border);
    font-weight: 600;
    padding: 12px 22px;
    text-align: left;
}
.main-content table td {
    padding: 5px 22px;
}
.main-content table tbody tr:nth-child(even) td {
    background: var(--bgGrey);
}
.main-content table td.centre.padded {
    padding: 22px 22px;
    opacity: 0.5;
}
.main-content table td ul {
    list-style: none;
}

/* Config page sidebars */
.main-content .sidebar {
    float: right;
    margin-right: 22px;
    margin-bottom: 22px;
    width: 345px;
}
.main.by-side {
    width: calc(90% - 345px);
}
.main.by-side .sxn + .sxn {
    margin-top: 52px;
}

/* Sample widget elements */
.launch-btn {
    padding: 20px 32px 20px 58px;
    background: url('../img/chatback/white-icon.webp') left 20px top 18px / 24px auto no-repeat;
    border-radius: 10px;
    box-sizing: border-box;
    max-width: 250px;
    margin-bottom: 12px;
    cursor: pointer;
    border: none;
}
.launch-btn.dark {
    background-image: url('../img/chatback/dark-icon.webp');
}
.launch-btn.meetami {
    background-image: url('../img/ami/launch-icon.webp');
    background-size: 28px auto;
    padding-left: 64px;
}
.launch-btn p {
    color: var(--txtWhiteBrill);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.1em;
    margin-bottom: 0;
}
.launch-btn.dark p {
    color: var(--txtBlack);
}

.sample-widget {
    border-radius: 14px;
    box-shadow: 0 2px 5px rgba(173,173,173,0.5);
    margin-bottom: 12px;
    color: var(--txtBlack);
    background: var(--bgWhite);
}
.sample-widget .widget-header {
    padding: 26px 32px;
    border-radius: 14px 14px 0 0;
    color: var(--txtWhiteBrill);
}
.sample-widget.dark .widget-header {
    color: var(--txtBlack);
}
.sample-widget .widget-header .close-icon {
    width: 22px;
    height: 22px;
    float: right;
    background: url('../img/chatback/close-icon.svg') center center / 22px auto no-repeat;
    cursor: pointer;
}
.sample-widget.dark .widget-header .close-icon {
    background-image: url('../img/chatback/close-icon-dark.svg');
}
.sample-widget .widget-header h5 {
    font-weight: 700;
    font-size: 19px;
}
.sample-widget .widget-body {
    padding: 30px 32px;
}
.sample-widget .widget-body .msg {
    width: 80%;
    padding: 16px 20px;
    background: var(--bgGrey);
    box-sizing: border-box;
    font-weight: 500;
    border-radius: 14px 14px 14px 0;
    margin-right: auto;
    font-size: 15px;
}
.sample-widget .widget-body .msg.customer-msg {
    color: var(--txtWhiteBrill);
    margin-right: 0;
    border-radius: 14px 14px 0 14px;
    margin-left: auto;
}
.sample-widget.dark .widget-body .msg.customer-msg {
    color: var(--txtBlack);
}
.sample-widget .widget-body .msg + .msg {
    margin-top: 20px;
}
.sample-widget .widget-body .msg p {
    margin-bottom: 0;
}
.sample-widget .widget-sender {
    margin: 0 32px;
    border-top: 1px solid var(--border);
    padding-top: 12px;
    padding-bottom: 12px;
}
.sample-widget .widget-sender textarea {
    font-family: 'Montserrat', sans-serif;
    color: var(--txtBlack);
    font-weight: 500;
    font-size: 16px;
    border: none;
    width: 80%;
    height: 44px;
    resize: none;
    outline: none;
}
.sample-widget .widget-sender .send-btn {
    width: 20%;
    float: right;
    position: relative;
    height: 44px;
    cursor: pointer;
    font-size: 0;
}
.sample-widget .widget-sender .send-btn .top {
    position: absolute;
    top: 9px;
    left: 25px;
    clip-path: polygon(0 0, 24px 18px, 1px 10px);
    width: 100%;
    height: 100%;
}
.sample-widget .widget-sender .send-btn .btm {
  position: absolute;
  top: 22px;
  left: 25px;
  clip-path: polygon(2px 0, 24px 5px, 4px 10px);
  width: 100%;
  height: 100%;
}

/* Test widget on content pages */
.int-widget {
    position: fixed;
    bottom: 22px;
    right: 32px;
    max-width: 350px;
}
.int-widget .sample-widget .widget-body {
    max-height: 350px;
    overflow: auto;
}

/* Forms */
.form-scrn form {
    width: 75%;
}
.form-scrn form h3 {
    margin-top: 48px;
}

.input {
    margin-bottom: 25px;
}
.input label {
    font-size: 15px;
    margin-bottom: 12px;
    display: block;
    font-weight: 500;
    text-align: left;
}
.input label span {
    font-style: italic;
    display: block;
    opacity: 0.5;
    font-size: 14px;
}
.input input[type=text], .input input[type=email], .input input[type=password], select, .input textarea {
    display: block;
    width: 100%;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    background: var(--bgGrey);
    padding: 22px 19px;
    border-radius: 10px;
    box-sizing:border-box;
}
.input textarea {
    height: 200px;
    resize: none;
}
.input input[type=text]:focus,
.input input[type=email]:focus,
.input input[type=password]:focus,
select:focus,
.input textarea:focus {
    box-shadow: 0 0 5px var(--bgBlack);
    outline: none;
}
.input .val-msg {
  color: var(--red);
  text-align: left;
  margin-top: 5px;
  font-size: 14px;
}
.flash-msg {
  background: var(--red);
  color: var(--txtWhiteBrill);
  padding: 8px 12px;
  margin-bottom: 18px;
  border-radius: 12px;
}
.flash-msg.grn, .flash-msg.green {
    background: var(--grn);
    color: var(--txtBlack);
}

button.addn-btn {
    background: url('../img/chatback/add-icon.png') left center / 20px auto no-repeat;
    border: none;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    display: block;
    padding: 12px 12px 12px 32px;
    font-size: 15px;
    margin-bottom: 48px;
    cursor: pointer;
}
button.remove-btn {
  background: url('../img/chatback/minus.webp') left center / 16px auto no-repeat;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  display: block;
  padding: 2px 2px 2px 24px;
  font-size: 13px;
  margin-bottom: 0;
  cursor: pointer;
  opacity: 0.5;
}
button.remove-btn:hover {
  opacity: 0.25;
}

/* Preloader */
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  position: relative;
  border: 3px solid;
  border-color: #eee #eee transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: 10vh auto;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FF3D00 #FF3D00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.meetami .loader::before, .meetami .loader::after { border-color: transparent transparent var(--hotPink) var(--hotPink); }
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #eee #eee transparent transparent;
  animation: rotation 1.5s linear infinite;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    

/* External demo screen */
.agpt-demo {
    width: 100%;
    height: 860px;
    background-image: linear-gradient(to bottom right, #fff, #fff, #F4BE9D);
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    overflow: hidden;
}
.agpt-demo.meetami {
    background-image: linear-gradient(to bottom right, #fff, #fff, #fce0f0);
    box-shadow: none;
}
.agpt-demo .agpt-content {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}
.agpt-demo .agpt-content .half {
    width: 50%;
    float: left;
    box-sizing: border-box;
    padding: 0 32px;
}
.agpt-demo .agpt-content .agpt-demo-form.half {
    padding: 270px 90px 200px 0;
}
.agpt-demo .agpt-content .agpt-demo-form.half form {
    margin-top: 32px;
}
.agpt-demo .agpt-content h3 {
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 24px;
    text-transform: uppercase;
}
.agpt-demo .btn {
    color: var(--txtWhiteBrill);
    font-weight: 500;
    font-size: 18px;
}

.agpt-demo .agpt-content .agpt-demo-widget {
    position: relative;
    display: block;
    height: 860px;
}
.agpt-demo .agpt-content .agpt-demo-widget .phone-demo {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 780px;
    width: 100%;
    background: url('../img/chatback/phone.png') top center / 100% auto no-repeat;
    z-index: 10;
}
.agpt-demo .agpt-content .agpt-demo-widget .phone-bg {
    border: none;
    position: absolute;
    bottom: 0;
    height: 750px;
    width: 90%;
    margin: 0 auto;
    border-radius: 54px 54px 0 0;
    z-index: 2;
}

.agpt-demo .agpt-content .agpt-demo-widget .sample-widget {
    position: absolute;
    bottom: -600px;
    right: 11%;
    width: 76%;
    background: #fff;
    transition: 0.5s;
    z-index: 11;
}
.agpt-demo .agpt-content .agpt-demo-widget .sample-widget.visible {
    bottom: 22px;
}
.agpt-demo .agpt-content .agpt-demo-widget .sample-widget.visible .widget-body {
    max-height: 320px;
    overflow: auto;
}

.agpt-demo .agpt-content .agpt-demo-widget .sample-widget .widget-body {
    position: relative;
}
.agpt-demo .agpt-content .agpt-demo-widget .sample-widget .widget-body.space-btm {
    padding-bottom: 72px;
}
.agpt-demo .agpt-content .agpt-demo-widget .sample-widget .widget-body .widget-error {
    width: 100%;
    padding: 12px 18px;
    background: var(--red);
    color: #fff;
    font-size: 14px;
    z-index: 1;
    box-sizing: border-box;
    border-radius: 12px;
    margin-top: 12px;
}
.agpt-demo .agpt-content .agpt-demo-widget .sample-widget .widget-body .widget-error p {
    margin: 0;
}
.agpt-demo .agpt-content .agpt-demo-widget .phone-demo-glow {
    position: absolute;
    bottom: 0;
    right: 5%;
    height: 750px;
    width: 30%;
    margin: 0 auto;
    background: rgba(255,255,255,0.05);
    border-radius: 0 54px 0 0;
    z-index: 9;
}
.agpt-demo .agpt-content .agpt-demo-widget .phone-demo-placeholder {
    position: absolute;
    bottom: 0;
    height: 750px;
    width: 90%;
    margin: 0 auto;
    background: #222;
    border-radius: 54px 54px 0 0;
    z-index: 1;
    text-align: center;
    color: #fff;
}
.agpt-demo .agpt-content .agpt-demo-widget .phone-demo-placeholder h4 {
    margin: 200px auto 18px auto;
    max-width: 340px;
    font-size: 24px;
    display: block;
}
.agpt-demo .agpt-content .agpt-demo-widget .phone-demo-placeholder img {
    width: 48px;
    height: 48px;
    margin-top: 32px;
}

/* Responsive styling */
header.sidebar button.menu-toggle { display: none; }
@media only screen and (max-width: 1066px) {
    .progress a.btn.upgrade span { display: none; }
}

@media only screen and (max-width: 1032px) {

    .agpt-demo { height: 820px; }
    .agpt-demo .agpt-content .agpt-demo-form.half { padding-top: 240px; }
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo { height: 720px; }
    .agpt-demo .agpt-content .agpt-demo-widget .phone-bg,
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo-placeholder,
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo-glow { 
        height: 690px;
        border-radius: 44px 44px 0 0; 
        bottom: 5px;
    }
    .agpt-demo .agpt-content .agpt-demo-widget .sample-widget.visible { bottom: 48px; }

}

@media only screen and (max-width: 972px) {
    .dashboard .stats.thirds>div {
        min-height: 200px;
    }
}

@media only screen and (max-width: 960px) {

    .main-content {
        width: 90%;
        margin: 108px auto 42px auto;
    }

    header.sidebar {
        height: auto;
        width: 100%;
        padding: 22px;
        z-index: 5;
        transition: 0.5s;
    }
    header.sidebar .logo { 
        margin-bottom: 0; 
        float: left;
    }
    header.sidebar .menu, header.sidebar .usr-menu {
        display: block;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        left: 0;
        height: 0;
        clear: both;
        overflow: hidden;
        transition: 0.5s;
    }
    header.sidebar .menu.open,
    header.sidebar .usr-menu.open {
        height: auto;
        margin-top: 52px;
    }
    header.sidebar .usr-menu.open {
        margin-top: 48px;
        padding-top: 22px;
        border-top: 1px solid rgba(255,255,255,0.05);
    }

    header.sidebar button.menu-toggle {
        display: block;
        position: relative;
        float: right;
        margin: -4px 22px 0 0;
        padding: 10px 0;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        color: var(--txtWhite);
        text-transform: uppercase;
        font-weight: 600;
    }
    header.sidebar button.menu-toggle:before, header.sidebar button.menu-toggle:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background: var(--txtWhite);
        transition: 0.5s;
    }
    header.sidebar button.menu-toggle:after {
        top: auto;
        bottom: 0;
    }
    header.sidebar button.menu-toggle.open {
        color: var(--bgBlack);
    }
    header.sidebar button.menu-toggle.open:before,
    header.sidebar button.menu-toggle.open:after {
        height: 2px;
        transform: rotate(45deg);
        top: 16px;
    }
    header.sidebar button.menu-toggle.open:after {
        transform: rotate(-45deg);
        bottom: auto;
    }

    .form-scrn form {
        width: 100%;
    }

}

@media only screen and (max-width: 920px) {

    .agpt-demo { height: auto; }
    .agpt-demo .agpt-content .half { width: 90%; margin: 0 auto; float: none; }
    .agpt-demo .agpt-content .agpt-demo-form.half { padding: 72px 0 42px 0; }
    .agpt-demo .agpt-content .agpt-demo-widget { height: 750px; }
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo { 
        width: 520px; 
        height: 720px; 
        margin: 0 auto; 
        right: 50%;
        margin-right: -260px;
    }
    .agpt-demo .agpt-content .agpt-demo-widget .phone-bg,
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo-placeholder,
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo-glow { 
        height: 692px;
        width: 472px;
        border-radius: 52px 52px 0 0; 
        bottom: 0;
        right: 50%;
        margin-right: -242px;
    }
    .agpt-demo .agpt-content .agpt-demo-widget .sample-widget.visible { 
        bottom: 22px; 
        width: 410px;
        right: 50%;
        margin-right: -210px;
    }

}

@media only screen and (max-width: 850px) {
    .med-hide { display: none; }
}

@media only screen and (max-width: 820px) {

    .main-content .sidebar {
        display: none;
    }
    .main.by-side {
        width: 100%;
    }
}

@media only screen and (max-width: 760px) {
    .dashboard .stats.thirds>div {
        min-height: 174px;
    }
    .dashboard .stats.thirds>div p span {
        font-size: 32px;
    }
}

@media only screen and (max-width: 700px) {

    .vertical-tabs ul.lake-tabs { width: 66px; }
    .vertical-tabs ul.lake-tabs li button { min-width: 0; }
    .vertical-tabs ul.lake-tabs li button span { display: none; }
    .vertical-tabs .sxn.open { width: calc(100% - 65px); }

}

@media only screen and (max-width: 600px) {

    .login-content p.sml.right { 
        float: none !important; 
        margin: 8px 0 0 0;
        width: auto;
        text-align: left !important;
    }

    .agpt-demo .agpt-content .agpt-demo-form.half { padding: 42px 0 22px 0; }
    .agpt-demo .agpt-content .agpt-demo-widget { height: 630px; }
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo { 
        width: 420px; 
        height: 600px; 
        margin: 0 auto; 
        right: 50%;
        margin-right: -210px;
    }
    .agpt-demo .agpt-content .agpt-demo-widget .phone-bg,
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo-placeholder,
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo-glow { 
        height: 575px;
        width: 380px;
        border-radius: 48px 48px 0 0; 
        margin-right: -192px;
    }
    .agpt-demo .agpt-content .agpt-demo-widget .sample-widget.visible { 
        bottom: 2px; 
        width: 320px;
        right: 50%;
        margin-right: -165px;
    }

}


@media only screen and (max-width: 560px) {
    .sml-hide { display: none; }
    .progress .bar { width: 68%; }
    .progress a.btn.upgrade { width: 30%; }
}

@media only screen and (max-width: 520px) {
    .dashboard .stats.thirds>div {
        min-height: 0;
    }
    .dashboard .stats.thirds>div p {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .dashboard .stats.thirds>div p span {
        font-size: 28px;
    }
}

@media only screen and (max-width: 400px) {

    .agpt-demo { padding: 0 0 32px 0; }
    .agpt-demo .agpt-content .agpt-demo-widget { height: auto; }
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo,
    .agpt-demo .agpt-content .agpt-demo-widget .phone-bg,
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo-placeholder,
    .agpt-demo .agpt-content .agpt-demo-widget .phone-demo-glow { 
        display: none;
    }
    .agpt-demo .agpt-content .agpt-demo-widget .sample-widget.visible { 
        width: 120%;
        position: relative;
        margin: 42px -10% 10px -10%;
        right: auto;
    }

}