/* Core registration elements */
.registration {
    background: var(--bgWhite);
    text-align: center;
}
.registration header.setup {
    background: var(--bgOrangeFaded);
    width: 100%;
    height: 468px;
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 220px);
    padding: 80px 0 42px 0;
}
.meetami.registration header.setup { background: var(--pinkBg); }
.registration header.setup h1 {
    font-size: 52px;
    margin-bottom: 12px;
}
.registration header.setup h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 600;
}
.registration header.setup p {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 500;
    max-width: 580px;
    margin: 0 auto 14px auto;
}
.reg-content, .login-content {
    position: relative;
    width: 80%;
    max-width: 520px;
    display: block;
    margin: 232px auto 72px auto;
    min-height: 500px;
}
.login-content {
    background: var(--bgWhite);
    border-radius: 14px;
    box-shadow: 0 2px 5px 0 rgba(173, 173, 173, 0.5);
    min-height: auto;
    padding: 32px;
}
.reg-content.big {
    max-width: 1280px;
    overflow: visible;
    margin-top: 312px;
    width: 90%;
}
.reg-content .reg-steps {
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    position: absolute;
    background: var(--bgWhite);
    padding: 32px;
    box-sizing: border-box;
    opacity: 1;
    transition: 0.25s;
    box-shadow: 0 2px 5px 0 rgba(173,173,173,0.5);
    border-radius: 14px;
    margin-bottom: 120px;
}
.reg-content.big .reg-steps {
    background: none;
    box-shadow: none;
    text-align: left;
}
.reg-content .reg-steps.hiding {
    left: -550px;
    opacity: 0;
}
.reg-content .reg-steps.appearing {
    left: 550px;
    opacity: 0;
}
.reg-content .reg-steps button.btn.primary, .login-content button.btn.primary, .login-content a.btn.primary {
    background-image: url('../img/chatback/nxt-icon.png');
    background-position: right 22px center;
    background-repeat: no-repeat;
    background-size: 7px auto;
    margin-top: 22px;
    text-align: center !important;
}
.meetami .reg-content .reg-steps button.btn.primary,
.meetami .login-content button.btn.primary,
.meetami .login-content a.btn.primary {
    background-image: url('../img/ami/nxt-icon.webp');
}
.meetami .login-content a.btn.primary.wider {
    background-image: none;
}
.login-content button.btn.primary {
    margin: 32px auto 0 0;
    display: block;
    width: auto;
}
.reg-content .reg-steps button.btn.primary.sml, a.btn.primary.sml {
    padding: 10px 18px;
    background-image: none;
    min-width: 0;
    margin-top: 0;
    background-color: var(--lghtGrn);
    font-size: 14px;
    margin-bottom: 0;
}
.reg-content .reg-steps button.btn.primary.sml:hover {
    opacity: 0.6;
}

p.sml.centred.login-lnk {
    margin-top: 12px;
    margin-bottom: 0;
}
p.sml.centred.login-lnk a {
    border-bottom: 1px solid var(--txtGrey);
    display: inline-block;
    transition: 0.3s;
}
p.sml.centred.login-lnk a:hover {
    border-bottom-color: transparent;
}

.reg-content.big .reg-steps h2 {
    margin-bottom: 32px;
}
.reg-content.big .reg-steps .sidebar {
    float: left;
    width: 342px;
    box-sizing: border-box;
}
.reg-content.big .reg-steps .main {
    margin-left: 382px;
}
.reg-content.big .reg-steps .main .sxn {
    background: var(--bgWhite);
    box-shadow: 0 2px 5px 0 rgba(173,173,173,0.5);
    padding: 32px 36px 22px 36px;
    border-radius: 14px;
    margin-bottom: 36px;
}
.reg-content.big .reg-steps .main .sxn h3 {
    font-size: 22px;
}
.reg-content.big .reg-steps button.secondary {
    float: left;
    margin-top: 22px;
}
.reg-content.big .reg-steps button.mlft {
    margin-left: 225px;
}
button.primary.wide { width: 200px; padding-right: 38px; } 
button.primary.wider, a.primary.wider { width: 280px; } 
.login-content p.sml {
    margin-top: -26px;
    text-align: right;
    width: 60%;
    margin-bottom: 0;
    opacity: 0.5;
}

.login-content .new-usr {
    margin-top: 48px;
    text-align: left;
    background: var(--bgGrey);
    padding: 32px;
}
.login-content .new-usr h3 {
    margin-bottom: 8px;
}
.login-content .new-usr p {
    margin-bottom: 0;
    font-size: 15px;
}

/* Colour picker */
.colour-input {
    position: relative;
}
.colour-input .picker-wrap {
    display: none;
    position: absolute;
    left: 16px;
    top: 62px;
}
.colour-input .picker-wrap.open {
    display: block;
}
.colour-input button.colour-swatch {
    width: 64px;
    height: 64px;
    border: none;
    border-radius: 10px;
    margin-right: 18px;
    float: left;
    font-size: 0;
    cursor: pointer;
}
.input .colour-input input[type=text] {
    width: 145px;
    display: inline-block;
}

/* Vertical tabs */
.vertical-tabs {
    position: relative;
    height: 530px;
}
.vertical-tabs ul.lake-tabs {
    width: 225px;
    list-style: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.vertical-tabs ul.lake-tabs li {
    display: block;
    margin-bottom: 5px;
}
.vertical-tabs ul.lake-tabs li button {
    background: var(--bgGrey);
    width: 100%;
    padding: 18px 22px;
    box-sizing: border-box;
    border: 1px solid var(--border);
    text-align: right;
    cursor: pointer;
    border-radius: 10px 0 0 10px;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.vertical-tabs ul.lake-tabs li.active button {
    background: var(--bgWhite);
    border-right-color: var(--bgWhite);
}
.vertical-tabs .sxn {
    display: none;
}
.vertical-tabs .sxn.open {
    display: block;
    width: calc(100% - 224px);
    padding: 32px;
    box-sizing: border-box;
    height: 525px;
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid var(--border);
    z-index: 0;
    background: var(--bgWhite);
    border-radius: 0 10px 10px 10px;
    overflow: auto;
}
.main-content .vertical-tabs .sxn.open {
    height: auto;
}
.vertical-tabs .textarea > div {
    border: none;
    background: var(--bgGrey);
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    border-radius: 10px;
    min-height: 200px;
}

/* Password strength meter */
#pwForm { position: relative; }
.strength-meter {
    position: absolute;
    top: 80px;
    left: 0px;
    width: 420px;
    background: #fff;
    padding: 18px 25px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    border-radius: 12px;
    font-size: 14px;
    z-index: 1;
    display: none;
    text-align: left;
}
.strength-meter.display {
    display: block;
}
.strength-meter p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0
}
.strength-meter ul {
    list-style: none;
}
.strength-meter ul li {
    padding-left: 28px;
    line-height: 16px;
    margin: 14px 0;
    background: url('./../img/cross.svg') center left / 16px auto no-repeat;
}
.strength-meter ul li.ticked {
    background: url('./../img/tick.svg') center left / 16px auto no-repeat;
}

/* Responsiveness */
@media only screen and (max-width: 960px) {

    .reg-content.big .reg-steps .sidebar { display: none; }
    .reg-content.big .reg-steps .main { margin-left: 0; }

    header.setup p {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

}